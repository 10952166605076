import { axiosAccountInstance } from "api/Request";

// import { getTokenValidity } from "helpers/getTokenValidity";

export const getPendingCountInfo = () => (dispatch) => {
  let url = `/booking/pending_requests`;

  dispatch({
    type: "PENDING_COUNT_REQUEST_LIST_BEGINS",
  });

  axiosAccountInstance
    .get(url)
    .then(
      (result) => {
        if (result.data.statusCode === 200) {
          dispatch({
            type: "PENDING_COUNT_REQUEST_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
      }
      // (err) => {
      //   if (err.data.statusCode === 400) {
      //     toast.error("No Data Found");
      //     dispatch({
      //       type: "PENDING_COUNT_REQUEST_LIST_FAILURE",
      //       payload: "FALIURE",
      //     });
      //   }

      //   // const error = err.response
      //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   //   getTokenValidity(error.config)
      //   // }else{
      //   //   console.log("error couldnot be solved")
      //   // }
      // }
    )
    .catch((err) => {
      dispatch({
        type: "PENDING_COUNT_REQUEST_LIST_FAILURE",
        payload: "FALIURE",
      });
    });
};

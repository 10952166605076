import React, { useEffect, useState } from "react";
import PendingListCard from "./PendingListCard";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import { setPendingRequestInfo } from "redux/actions/admin/PendingRequestAction";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";

import pendingReq from "../../../../assets/img/icons/customIcons/filter.svg";
import CustomFilter from "components/customFilter";
function PendingList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  companyId,
  toggle,
  list,
  handleClearDate,
  isDashboard,
  handleSearchOnSelect,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [tableHeight, setTableHeigth] = useState(300);

  const [showToggle, setShowToggle] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const fetchResultPendingListRequests = useSelector(
    (state) => state.PendingRequestReducer.result
  );

  const fetchResultPendingListRequestsLoader = useSelector(
    (state) => state.PendingRequestReducer.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(setPendingRequestInfo("1", "1", finalData, companyId));
  };
  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");

    if (fetchResultPendingListRequests?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });
  return (
    <>
      <div className="d-flex">
        <Col id="tableDiv" md={isFilter ? "9" : "12"}>
          <Row className="mb-2">
            <Col md={"10"}>
              <h3 className="tab-title">{`${title} (${
                fetchResultPendingListRequests?.itemCount
                  ? fetchResultPendingListRequests?.itemCount
                  : "0"
              })`}</h3>
            </Col>

            <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className="common-outer-filter">
                {list && (
                  <div className="btn-group new-cutom-filter  ">
                    <button
                      type="button"
                      onClick={() => {
                        setShowToggle(!showToggle);
                        console.log("heyyyyy");
                      }}
                      className={"btn dropdown-toggle mr-3"}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img alt="" src={pendingReq} width={"18px"} />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right py-0">
                      {list?.map((item, key) => (
                        <button
                          style={{
                            backgroundColor:
                              item?.tabId === "1" ? "#5e72e4" : "none",
                            color: item?.tabId === "1" ? "white" : "none",
                          }}
                          key={key}
                          className="dropdown-item"
                          onClick={() =>
                            toggle({ target: { value: item?.tabId } })
                          }
                          type="button"
                        >
                          {item?.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {!isDashboard && (
                <div className="common-outer-filter">
                  <div className="btn-group new-cutom-filter ">
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <PendingListCard loader={fetchResultPendingListRequestsLoader} />

          <div
            style={{
              display: fetchResultPendingListRequestsLoader
                ? "none"
                : "contents",
            }}
          >
            <PaginationComponent
              totalCount={fetchResultPendingListRequests?.itemCount}
              handlePagination={handlePagination}
              activePage={fetchResultPendingListRequests?.activePage}
            />
          </div>
        </Col>
        { !isDashboard && (
          <Col
            md="3"
            style={{
              height: tableHeight,
              minHeight: "670px",
              display: isFilter ? "block" : "none",
            }}
          >
            <CustomFilter
              tableHeight={tableHeight}
              minHeight={"670px"}
              handleSearchOnSelect={handleSearchOnSelect}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleEmployeeSearchOnChange}
              type="outbound"
              list={list}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              handleClearDate={handleClearDate}
            />
          </Col>
        )}
      </div>
    </>
  );
}

export default PendingList;
